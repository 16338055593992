<template>
    <div>
        <div   class=" pb-9 diagonal pt-0 pt-md-5">
        <div class="py-4 mt--4 mb--3">
           <h3 class="mb-0 text-white pt-4">Obsah typu</h3>
            <h2 class=" mb--5">{{thistype}}</h2>
            <div class="text-right system-options w-100 d-block" style="">
                   <router-link :to="'/add-content/' + id" v-if="id"  class="nav-link" :href="'/add-content/' + id">
                            <template>
                                <a-button type="primary" ><i class="far fa-plus-square"></i> Nový obsah</a-button>
                            </template>
                        </router-link>
                <router-link :to="'/content-type/' + slugfields" v-if="this.id"  class="nav-link" :href="'/content-type/' + slugfields">
                    <template>
                        <a-button type="secondary" ><i class="fas fa-cogs pr-1"></i> Nastavenia</a-button>
                    </template>
                </router-link>
                <a-button type="secondary"><i class="fas fa-inbox-in"></i> Príjemka</a-button>

                    <a class="nav-link">   <a-upload
                                                        name="import"
                                                        :multiple="false"
                                                        :customRequest="importNode"
                                                        :headers="head"
                                                        :showUploadList="false">
                                                   <span class="btn text-default btn-sm btn-icon btn-neutral">      <span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-file-import"></i></span>Import</span>
                                                </a-upload></a>
                <a-popover  v-model="visible" placement="bottom" trigger="click">
                    <template slot="content">
                        <div class="row" v-if="data">
                            <div class="col-md-12">
                                <template v-for="(fil,ind) in filterBy">
                                    <div class="w-100" :key="ind">
                                        <label>{{fil.title}}</label>
                                        <a-select class="form-control-compact w-100"  style="border:0;" :default-value="fil.slug === maincategory ? tid : ''" @change="(e) => filt(e,fil.slug,fil.type)"  :options="fil.data"/>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                  <a class="nav-link"><a-button type="secondary" class=" btn-secondary btn-sm" size="small"><i class="fas fa-sort-down pr-2"></i> Filter</a-button></a>
                </a-popover>

            </div>
                </div>
            </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="filter-block" v-if="realFilter">
                        <ul class="d-inline" style="margin:0;padding:0;">
                            <template v-for="(filtered,find) in realFilter">
                                 <a-tag color="orange" title="Zrušiť filter"  :key="'filter-' + find" v-if="filterBy[filtered.slug]">{{filterBy[filtered.slug].title}}:
                                    <template v-for="option in filterBy[filtered.slug].data">
                                       <span class="value" style="text-transform:lowercase" @click="removeFilter(find)" :key="'filter-option-' + option.value" v-if="option.value === filtered.value">{{option.label}}
                                           <i class="fal fa-times-circle remove-filter pl-1 mt-1" style="color:red;"></i>
                                       </span>
                                    </template>
                                </a-tag>
                            </template>
                        </ul>
                    </div>
                    <div class="card shadow">
                        <div class="table-responsive">
                            <a-table :row-selection="rowSelection"
                                    :columns="columns"
                                     :pagination="pagination"
                                    :rowKey="record => record.id"
                                    :dataSource="filterProductsByName"
                                    @change="handleChange"
                                    :loading="loading"
                                    class="table-flush">

                                <template slot="url" slot-scope="url,record">
                                    <a v-if="url" :href="vars.site_url + '/' + record.language + '/' + url" target="_blank" style="color:#3c3c3c" class="">{{url}}</a>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_url" slot-scope="url,record">
                                <a v-if="url" :href="vars.site_url + '/' + record.language + '/' + url" target="_blank" style="color:#3c3c3c" class="">{{url}}</a>
                                <span v-else><i class="fal fa-times"></i></span>
                            </template>
                                <template slot="field_4" slot-scope="text">
                                     <a-popover  v-if="text" >
                                        <template slot="content">
                                          <span v-html="text"></span>
                                        </template>
                                        <a-button type="primary">
                                            <i class="fad fa-align-left"></i>
                                        </a-button>
                                    </a-popover>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_color" slot-scope="color">
                                    <span class="square" :style="'background-color:' + color" :title="color"></span>
                                </template>
                                <template slot="field_1" slot-scope="title,record">
                                    <a v-if="record.url" :href="vars.site_url + '/' + record.language + '/' + record.url" target="_blank" style="color:#3c3c3c" class=""><span v-if="title">{{title}}</span><span v-else><i class="fal fa-times"></i></span></a>
                                    <span v-else><span v-if="title">{{title}}</span><span v-else><i class="fal fa-times"></i></span></span>
                                </template>
                                <template slot="field_10" slot-scope="builder">
                                    <a v-if="Array.isArray(JSON.parse(builder))"><i class="fal fa-times"></i></a>
                                    <span v-else><i class="fal fa-check"></i></span>
                                </template>
                                <template slot="field_12" slot-scope="fieldoption">
                                    <div v-if="fieldoption">
                                    {{fieldoption}}
                                    </div>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_14" slot-scope="datefield">
                                    <div v-if="datefield">
                                        {{moment.unix(datefield).format('D.M.YYYY')}}
                                    </div>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_16" slot-scope="datefield">
                                    <div v-if="datefield">
                                      {{moment.unix(datefield[0][0]).format('D.M.YYYY')}} - {{moment.unix(datefield[0][1]).format('D.M.YYYY')}}
                                    </div>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="datetime" slot-scope="datefield">
                                    <div v-if="datefield">
                                      {{moment(datefield).format('DD.MM.YYYY HH:mm')}}
                                    </div>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_8" slot-scope="datefield">
                                <div v-if="datefield">
                                   {{datefield}}
                                </div>
                                <span v-else><i class="fal fa-times"></i></span>
                            </template>
                                <template slot="field_2" slot-scope="datefield">
                                    <div v-if="datefield">
                                        {{datefield}}
                                    </div>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_3" slot-scope="image">
                                    <img v-if="JSON.parse(image).uri" :src="IMG_URL + 'suggestions' + JSON.parse(image).uri" width="50">
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_11" slot-scope="image,record">
                                   <div style="max-width:130px">
                                    <a-carousel arrows>
                                        <div
                                                slot="prevArrow"
                                                class="custom-slick-arrow"
                                                style="left: 10px;zIndex: 1"
                                        >
                                            <i class="fal fa-chevron-left"></i>
                                        </div>
                                        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
                                            <i class="fal fa-chevron-right"></i>
                                        </div>
                                        <div :key="'g' + record.id + iie" v-for="(img,iie) in image" class="">
                                            <img v-if="img.image.uri" :src="IMG_URL + 'suggestions' + img.image.relative">
                                        </div>
                                    </a-carousel>
</div>
                                </template>
                                <template slot="field_5" slot-scope="number">
                                    <span v-if="number">{{number}}</span>
                                    <span v-else><i class="fal fa-times"></i></span>
                                </template>
                                <template slot="field_7" slot-scope="published">
                                 <span v-if="published"><i class="fal fa-check"></i></span>
                                    <span v-if="!published"><i class="fal fa-times"></i></span>
                                    <span v-if="null"></span>

                                </template>

                                <template slot="name" slot-scope="user" styyle="background-color:red">
                                   <span class="actions">

                                       <router-link :to="'/edit-content/' + user.id" class="btn text-default btn-icon btn-secondary btn-sm" :href="'/edit-content/' + user.id">
                                        <span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</router-link>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(user.id)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                    </a-popconfirm>
                                   </span>
                                </template>

                            </a-table>
                            <div  style="bottom:0" class="action-panel w-25 m-3 float-left"  v-if="rowSelection.selectedRowKeys.length > 0">
                                <a-select style="width:150px;"  class="mr-3" size="small" placeholder="Vyberte si akciu" v-model="action" :options="actions"></a-select>
                                <a-popconfirm placement="topRight"
                                              @confirm="doAction"
                                              @cancel="cancel"
                                              okText="Áno"
                                              cancelText="Nie"
                                >
                                    <template slot="title">
                                        <h5 color="danger" >Prajete si vykonať tento príkaz?</h5>
                                        Príkaz ovplyvní {{rowSelection.selectedRowKeys.length}} <span v-if="rowSelection.selectedRowKeys.length === 1">stránku</span> <span v-if="rowSelection.selectedRowKeys.length > 1 && rowSelection.selectedRowKeys.length < 5">stránky</span> <span v-if="rowSelection.selectedRowKeys.length > 4">stránok</span>.
                                    </template>
                                   <a-button type="warning">Vykonať</a-button>
                                </a-popconfirm>
                            </div>
                        </div>


                    </div><div class="quick-links" v-if="contenttypes">
                    <div class="card-footer pb-5" style="display:inline-block;width:100%;">
                        <label style="float:left;display:inline;margin-right:20px;" class="text-muted">Rýchle odkazy obsah: </label>
                       <span  :key="'ct' + carr.slug" v-for="carr in contenttypes" >

                              <a-dropdown  class="m-1 float-left"  placement="topCenter" v-if="filterAll[carr.id][carr.maincategory] && perm.includes('content-specific-item')">
                                    <router-link  :to="'/content/' + carr.slug"  class="m-1 float-left"  :href="'/content/' + carr.slug">
                                    <a-button  type="outline-primary" :class="'extramuted text-muted' + (carr.slug === id  ? ' selected' : '')" >{{carr.title}}</a-button>
                                    </router-link> <a-menu  class="choose-subcontent"  slot="overlay">
                                        <template v-for="item in filterAll[carr.id][carr.maincategory].data">
                                            <a-menu-item :key="item.value + '_' + carr.id" v-if="item.value != ''">
                                                <router-link :to="'/content/' + carr.slug + '/' + item.value">{{item.label}}</router-link>
                                            </a-menu-item>
                                        </template>
                                    </a-menu>
                              </a-dropdown>
                          <router-link v-else :to="'/content/' + carr.slug"  class="m-1 float-left"  :href="'/content/' + carr.slug">
                                <a-button type="outline-primary" :class="'extramuted text-muted' + (carr.slug === id  ? ' selected' : '')" >{{carr.title}}</a-button>
                        </router-link>
</span>

                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../api";
     import {dataService} from "../_services";
    import moment from 'moment';
    import {authHeader} from "../_helpers";
    import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
    import {IMG_URL} from "../api";
    import {Button,Select,Dropdown, Upload, Popconfirm,Popover,Tag,Carousel} from 'ant-design-vue';

    const columns = [
        {
            title: 'Názov',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Vytvorené',
            dataIndex: 'created',
        },
        {
            title: 'Jazyk',
            scopedSlots: {customRender: 'language'},
            dataIndex: 'language',
        },
        {
            title: 'Url',
            dataIndex: 'url',
            scopedSlots: {customRender: 'url'},
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];
    export default {
        name: "allitems",
        props: ["id","tid"],
        components: {
            "a-button": Button,
            "a-select": Select,
            "a-popconfirm": Popconfirm,
            "a-popover": Popover,
            "a-carousel": Carousel,
            "a-upload": Upload,
              "a-tag": Tag,
              "a-dropdown": Dropdown,
        },
        data() {
            return {
                data: [],
                vars:{},
                colorsto: JSON.parse(localStorage.colors),
               url: getURL(),
                ctt: [],
                loading: false,
                head: authHeader(),
                contenttypes:[],
                locale,
                moment,
                pagination: {
                    hideOnSinglePage: true,
                    pageSize: 20, // default number of pages per page
                    showSizeChanger: true, // display can change the number of pages per page
                    pageSizeOptions: ['10', '20', '30', '40'], // number of pages per option
                    showTotal: total => `Položiek: ${total}`, // show total
                    showSizeChange: (current, pageSize) => {  this.pageSize = pageSize; }, // update display when changing the number of pages per page
                },
                fieldtitle:"",
                visible: false,
                filterBy:[],
                realFilter:JSON.parse(localStorage.filter),
                perm:JSON.parse(localStorage.user).data.paths,
                filterValues:{},
                actions: [{"value":"delete","label":"Zmazať"},{"value": "export","label": "export"}],
                thistype:"",
                selectedRowKeys: [],
                action:undefined,
                selectValue:[],
                IMG_URL,
                filterAll:{},
                maincategory:null,
                slugfields:[],
                filteredInfo: null,
                columns,
                sortedInfo: null,
                showon: []
            };
        },
        computed: {
            filterProductsByName: function() {
                return this.data.filter(product => {
                    let i = 0;
                    var removefields = 0;
                   for (const [slug, value] of Object.entries(this.realFilter)) {

                       if(!value.value)
                       this.removeFilter(slug);

                        if(this.filterBy[value.slug])
                        {
                       if((value.value != "-" && value.value != "notassigned") && product[value.slug] !== null)
                       {
                       if(value.type === 12)
                       {

                           if(product[value.slug].includes(value.value) || product[value.slug] === value.value)
                           {
                               i = i + 1;
                           }

                       }
                       else {
                          let compareto = value.value;
                         if(value.value == "true")
                         compareto = true;
                         if(value.value == "false")
                         compareto = false;
                         if(product[value.slug] === compareto)
                        {
                            i = i + 1;
                        }
                       }
                       }
                       else {
if(value.type === 7 && (value.value == "notassigned") && (product[value.slug] === null || product[value.slug] === ""))
i = i + 1;
if(value.type === 7 && (value.value == "-" && (product[value.slug] === false || product[value.slug] === null || product[value.slug] === "" || product[value.slug] === true)))
i = i + 1;
if(value.type === 12 && (value.value == "-"))
i = i + 1;
if(value.type === 12 && (value.value == "notassigned" && (product[value.slug] === false || product[value.slug] === null || product[value.slug] === "" || product[value.slug] === true)))
i = i + 1;
                          // removefields = removefields + 1;
                       }
                    }
                    else
                    {
                       removefields = removefields + 1;
                    }
                    var size = 0 - removefields, key;
                    for (key in this.realFilter) {
                        if (this.realFilter.hasOwnProperty(key)) size++;
                    }  }
  if(this.realFilter.length == 0)
  return true;
                    if(size === i)
                    return true
                    else
                    return false
                })
            },
            rowSelection() {
                const { selectedRowKeys } = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: true,
                    onSelection: this.onSelection,
                };
            },
        },
        mounted() {
            this.fetch();
        },
        methods: {

    importNode(e)  {
        let formData = new FormData();
          formData.append('upload', e.file);
          formData.append('ctype', this.id);
       var pa = this;
                this.axios.post(this.url.API_URL + '/import',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
 pa.data.unshift(...data.data.data);
                });

                },
                doAction()
                {
                    if(this.action)
                    {
                    dataService.axiosPost('action/' + this.action, { 'nodes' : this.rowSelection.selectedRowKeys}).then((result) => {
                        this.rowSelection.selectedRowKeys = [];

                        if(this.action === "delete")
                        this.fetch();
                        if(this.action === "export")
                        {


        const blob = new Blob([JSON.stringify(result.data.data)], { type: 'application/json' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'export-' + this.id;
        link.click()
        URL.revokeObjectURL(link.href)
                        }




                    });
                   }
                },
        confirm(e) {
                dataService.axiosDelete('content', e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            hasValue(obj, key, value) {
                return obj.hasOwnProperty(key) && obj[key] === value;
            },
            removeFilter(index)
            {
                this.realFilter.splice(index, 1);
                localStorage.setItem('filter', JSON.stringify(this.realFilter));
            },
            filt(w,slug,type)
            {
                 for (var key in this.realFilter) {
                   if (this.realFilter[key]["slug"] === slug)
                   {
                        delete this.realFilter[key];
                   }
                  }
             let filtered =  this.realFilter.filter(function () { return true });
             this.realFilter = filtered;
             this.realFilter.push({"slug": slug, 'type': parseInt(type), "value": w});
             localStorage.setItem('filter', JSON.stringify(this.realFilter));
             },
            cancel() {
            },
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            filterIt()
            {
            },
            handleChange(pagination, filters, sorter) {
                this.filteredInfo = filters;
                this.sortedInfo = sorter;





const order = {
  field: sorter.field,
  asc: sorter.order === 'descend' ? true : false,
}

this.data = this.data.sort((a, b) => {
  if (order.asc) {
    if (a[order.field] > b[order.field]) {
      return 1
    } else if (a[order.field] < b[order.field]) {
      return -1
    } else {
      return 0
    }
  }
});




            },
            clearFilters() {
                this.filteredInfo = null;
            },
            hide() {
                this.visible = false;
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch("variable/site_url").then(results => {
                    this.vars = results;
                })
dataService.axiosFetch("contenttypes").then(results => {
                    this.contenttypes = results;

                    results.forEach(obj => {
                        var iid = obj.id;
                        var vval = obj.title;
                        this.ctt.push({"text" : vval,"value" : iid});
                        if(obj.filters)
                        this.filterAll[iid] = obj.filters;
                        if(obj.slug === this.id)
                        {
                            this.thistype = obj.title;

                          if(obj.filters)
                          {
                            this.filterBy = obj.filters;
                            let ke = Object.keys(obj.filters);
                            ke.forEach(j => {
                                this.filterValues[j] = "";
                            });
                           }
                            if(obj.fields)
                            {

obj.fields.forEach(oo => {
if(oo.sorter)
oo.sorter = (a, b) => a.name.length - b.name.length;
});

                                this.columns = obj.fields;
                                this.columns.push(
                                    {
                                        title: 'Vytvorené',
                                        dataIndex: 'created',
                                         sorter: (a, b) => a.created - b.created,
                                          sortDirections: ['descend', 'ascend'],
                                        scopedSlots: {customRender: 'datetime'},
                                    },
                                    {
                                        align: 'right',
                                        slots: {'title': 'customTitle'},
                                        scopedSlots: {customRender: 'name'},
                                    }
                                );
                            }
                            this.fieldtitle = vval;
                            this.slugfields = obj.id;
                            this.maincategory = obj.maincategory;

                            if(this.tid !== undefined) this.filt(this.tid,this.maincategory,12)
                        }
if(!this.filterBy)
    this.visible = false;
                    });


                });
                dataService.axiosFetch('content/' + this.id,true).then(results => {
                    this.data = results;
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
ul.ant-pagination {
margin-top: 0;
margin-bottom: 1em;
}
.ant-carousel >>> .slick-slide {
text-align: center;
height: 160px;
line-height: 160px;
background: #364d79;
overflow: hidden;
}
#app .smaller .ant-select-selection__rendered,
#app .smaller .ant-select-selection--single
{line-height:40px;height:40px;margin-right:10px;}
.system-options
{width:25%;}
.ant-carousel .custom-slick-arrow {
width: 25px;
height: 25px;
line-height: 20px;
font-size: 14px;
color: #fff;
background-color: rgba(31, 45, 61, 0.41);
border-radius: 100%;
opacity: 0.9;
top:43%;
padding: 4px;
padding-left: 8px;
}
.ant-carousel .custom-slick-arrow:before
{display:none}
.ant-carousel .custom-slick-arrow:hover {
opacity: 0.5;
color:#fff;
background: #2d2d2d;
}

.ant-carousel .slick-slide img {
width:100%;
}
.ant-popover-inner-content
{max-width:500px;}
.actions
{display: block;
width: 175px;
float:right;
text-align: right;}

.extra-link
{
    display:inline-block;
}
.square{
display:block;
width:20px;
height:20px;
}
.nopad a
{padding:0;}

.ant-table-pagination.ant-pagination {
margin-bottom: 40px;
margin-right: 20px;
}
.infield-gallery-image
{float:left;
width:50%;
}
.infield-gallery-image img
{width:100%;}
.ant-pagination-item {
border: 0;
}

.ant-table {
border-bottom: 1px solid rgba(0, 0, 0, 0.05);
margin-bottom: 20px;
}

.ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
-webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
border: 0;
}
.filter-block .remove-filter
{cursor:pointer;}
.filter-block
{background-color: #fafafa;
border-top-right-radius:10px;
margin-bottom:-10px;
position:relative;z-index:99;
border-top-left-radius:10px;
padding:10px;text-align:right;}
.ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
padding: 0;
margin: 0 3px;
border-radius: 50% !important;
width: 36px;
height: 36px;
font-size: 0.875rem;
}


.ant-pagination-item.ant-pagination-item-active a {
z-index: 1;
color: #fff;
background-color: #5e72e4;
border-color: #5e72e4;
}
.quick-links label
{font-size:12px;line-height:35px;}
.extramuted
{border-color: #deebff;font-size:12px;
background-color:#fff;}
.extramuted.selected
{border-color:#5e72e4}
.extramuted:hover
{background-color: #5e72e4;
color:#fff!important;}
.ant-pagination-item-link.ant-pagination-disabled a {
color: #8898aa;
pointer-events: none;
cursor: auto;
background-color: #fff;
border-color: #dee2e6;
}

.ant-dropdown-open .btn
{    background-color: #5e72e4;
color:#fff!important;
border-color: #5e72e4;}

</style>
